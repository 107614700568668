import { addPropertyControls, ControlType } from "framer"
import { useState } from "react"
import axios from "axios"

/**
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function ChatGPTAssistant(props) {
    const [messages, setMessages] = useState([])
    const [input, setInput] = useState("")

    const sendMessage = async (message) => {
        setMessages([...messages, { user: "You", text: message }])
        setInput("")
        try {
            const response = await axios.post(
                "https://api.openai.com/v1/chat/completions",
                {
                    model: "gpt-4", // Specify the correct model
                    messages: [{ role: "user", content: message }],
                },
                {
                    headers: {
                        Authorization: `Bearer ${props.apiKey}`,
                        "Content-Type": "application/json",
                    },
                }
            )
            const botMessage = response.data.choices[0].message.content
            setMessages([
                ...messages,
                { user: "You", text: message },
                { user: "Bot", text: botMessage },
            ])
        } catch (error) {
            console.error("Error communicating with ChatGPT:", error)
        }
    }

    return (
        <div
            style={{
                ...props.style,
                padding: "16px",
                border: "1px solid #ccc",
                borderRadius: "8px",
            }}
        >
            <div
                style={{
                    height: "300px",
                    overflowY: "auto",
                    marginBottom: "8px",
                }}
            >
                {messages.map((msg, index) => (
                    <div key={index} style={{ marginBottom: "8px" }}>
                        <strong>{msg.user}:</strong> {msg.text}
                    </div>
                ))}
            </div>
            <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                style={{
                    width: "calc(100% - 82px)",
                    marginRight: "8px",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                }}
            />
            <button
                onClick={() => sendMessage(input)}
                style={{
                    padding: "8px 16px",
                    borderRadius: "4px",
                    background: "#007bff",
                    color: "#fff",
                    border: "none",
                }}
            >
                Send
            </button>
        </div>
    )
}

ChatGPTAssistant.displayName = "ChatGPT Assistant"

addPropertyControls(ChatGPTAssistant, {
    apiKey: {
        type: ControlType.String,
        title: "API Key",
        displayTextArea: true,
    },
})
